.card {
    position: relative;
    flex: 0 0 90%;
    max-width: 46%;
    height: 320px;
    border-radius: 3px;
    border: 0.1rem solid var(--text-color);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(80%);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 0.1rem solid var(--text-color);
    border-radius: 3px;
    background-color: var(--overlay);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.card:hover .overlay {
    opacity: 1;
    animation: animOverlay 0.5s ease 0s 1 normal forwards;
}

.overlay h4 {
    font-family: 'Cinzel', serif;
    font-size: 1.8rem;
    color: var(--main-color);
}


.overlay p:nth-child(2) {
    text-align: center;
    font-family: 'EB Garamond', serif;
    font-size: 1.4rem;
    margin: 0.8rem 0;
}

.line {
    position: relative;
    text-align: center;
    font-family: 'Cormorant', serif;
    font-size: 1.5rem;
    font-weight: 700;
}

.line::before {
    content: ' ';
    position: absolute;
    border-bottom: 1px solid var(--text-color);
    width: 25%;
    height: 16px;
    display: inline;
    margin-left: calc(-25% - 8px);
}

.line::after {
    content: ' ';
    position: absolute;
    border-bottom: 1px solid var(--text-color);
    width: 25%;
    height: 16px;
    display: inline;
    margin-left: 8px;
}

.overlay p {
    color: var(--text-color);
    margin: 1.2rem 0;
}

.links {
    margin-right: 5px;
}

.tag ul {
    margin: 1rem 0;
}

.tag li {
    display: inline-block;
    padding: 0.2rem 0.8rem;
    margin-right: 5px;
    border-radius: 3px;
    border: 0.1rem solid var(--main-color);
    font-family: 'EB Garamond', serif;
    font-size: 1.1rem;
}

.overlay .btn-box {
    display: inline-block;
    margin-top: 0;
}

.overlay .btn-box .btn {
    width: 12rem;
    font-size: 1.1rem;
}

@media screen and (max-width: 998px) {
    .card {
        max-width: 85%;
    }
}

@media screen and (max-width: 480px) {
    .overlay h4 {
        font-size: 1.3rem;
    }

    .overlay p:nth-child(2) {
        font-size: 1rem;
    }

    .line {
        font-size: 1.2rem;
    }

    .tag li {
        font-size: 0.9rem;
    }

    .overlay .btn-box .btn {
        width: 10rem;
        font-size: 0.9rem;
        margin-right: 3.5rem;
    }
}

/* keyframes */

@keyframes animOverlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}