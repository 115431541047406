.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    background-color: var(--second-bg-color);
}

.gallery h3 {
    margin-bottom: 3rem;
}

.card-container {
    display: flex;
    justify-content: space-evenly;

}

.carousel-button {
    position: absolute;
    top: 67%;
    transform: translateY(-50%);
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
    outline: none;
}

.carousel-button:hover {
    color: var(--main-color);
}

.prev-button {
    left: 50px;
}

.next-button {
    right: 50px;
}

.carousel-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--text-color);
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: var(--main-color);
}


@media screen and (max-width: 998px) {
    .dot {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .prev-button {
        left: -1px;
    }

    .next-button {
        right: -1px;
    }
}
