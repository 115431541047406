.skills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2.5rem;
}

.skills h3 {
    margin-bottom: 3rem;
}

.skills-content ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skills-content ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 10rem;
    height: 10rem;
    margin: 1rem;
    padding: 0.5rem;
    gap: 1rem;
    border-radius: 0.2rem;
    font-family: 'EB Garamond', serif;
    font-size: 1.3rem;
    box-shadow: 0 7px 14px var(--bg-color), 0 1px 2px var(--bg-color);
    transition: all 0.5s ease-in-out;
}

.skills-content ul li:hover {
    transform: scale(1.1) rotate(360deg);
    /* box-shadow: 0 14px 28px var(--bg-color), 0 10px 20px var(--bg-color); */
}

@media screen and (max-width: 480px) {
    .skills-content ul li {
        width: 7rem;
        height: 7rem;
        font-size: 1.1rem;
    }
}