.contact {
  text-align: center;
  height: 100vh;
  padding-bottom: 7rem;
}

.contact h3 {
  margin-bottom: 2rem;
}

.contact form {
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
}

.contact form .input-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box .input-field {
  position: relative;
  width: 49%;
  margin: .8rem 0;
}

.contact form .input-box .input-field input,
.contact form .textarea-field textarea {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  color: var(--text-color);
  background: transparent;
  outline: none;
  border-radius: .2rem;
  border: .1rem solid var(--main-color);
}

.contact form .input-box .input-field input::placeholder,
.contact form .textarea-field textarea::placeholder {
  color: var(--text-color);
  opacity: 0.5;
}

.contact form .focus {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--bg-color);
  opacity: 0.5;
  border-radius: 0.2rem;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.contact form .input-box .input-field input:focus~.focus,
.contact form .input-box .input-field input:valid~.focus,
.contact form .textarea-field textarea:focus~.focus,
.contact form .textarea-field textarea:valid~.focus {
  width: 100%;
}

.contact form .textarea-field {
  position: relative;
  margin: 0.8rem 0 1.2rem;
  display: flex;
}

.contact form .textarea-field textarea {
  resize: none;
}

.contact form .btn-box.btns .btn {
  cursor: pointer;
}