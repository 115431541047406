.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background: var(--second-bg-color);
}

.about-img {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-img img {
    width: 90%;
    border-radius: 50%;
    border: 0.2rem solid var(--main-color);
}

.about-img .circle-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    animation: spin 4s linear infinite;
    transition: all ease;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 0.1rem solid var(--second-bg-color);
    border-bottom: 0.1rem solid var(--second-bg-color);
    border-left: 0.1rem solid var(--main-color);
    border-right: 0.1rem solid var(--main-color);
}

.about-content {
    text-align: center;
}

.about-content p {
    font-size: 1.25rem;
    margin: 2rem 0rem;
    font-family: 'EB Garamond', serif;
}

.openclassroom {
    color: var(--main-color);
}

.btn-box.btns {
    display: inline-block;
    width: 15rem;
}


/* keyframes spin  */

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}