section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
}

.home {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 9%;
    background: url(../assets/home.jpg) no-repeat center center/cover;
}

.home-content {
    max-width: 60rem;
}

.home-content h1 {
    font-size: 5.1rem;
    font-family: 'Cinzel', serif;
}

.home-content .text-animate {
    font-size: 2.6rem;
    font-weight: 400;
    font-family: 'EB Garamond', serif;
    color: var(--main-color);
    /* -webkit-text-stroke: 1px var(--main-color); */
}

.home-sci {
    position: absolute;
    bottom: 4rem;
    width: 170px;
    display: flex;
    justify-content: space-evenly;
}

.home-sci a {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 0.1rem solid var(--main-color);
    border-radius: 50%;
    font-size: 20px;
    color: var(--main-color);
    z-index: 1;
    overflow: hidden;
    transition: all 0.5s ease;
}

.home-sci a:hover {
    color: var(--bg-color);
}

.home-sci a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--main-color);
    z-index: -1;
    transition: all 0.5s ease;
}

.home-sci a:hover::before {
    width: 100%;
}


/*-- Responsive  --*/

@media screen and (max-width: 1140px) {
    .home-content h1 {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 998px) {
    .home-content h1 {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 768px) {
    .home {
        background: none;
    }

    .home-content h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 480px) {
    .home-content .text-animate {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 376px) {
    .home-content h1 {
        font-size: 2.3rem;
    }
}
