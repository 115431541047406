.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 2rem 8%;
    z-index: 2;
    transition: 0.4s ease-in;
}

.header-bg {
    background-color: var(--second-bg-color);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    background-color: transparent;
}

.header .logo {
    font-size: 2.3rem;
    font-weight: 500;
    font-family: 'Cinzel', serif;
    color: var(--text-color);
}

.header .logo span {
    color: var(--main-color);
}

.hamburger {
    display: none;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    margin-left: 2rem;
    font-weight: 600;
    cursor: pointer;
}

.header .nav-item a {
    font-size: 1.3rem;
    color: var(--text-color);
    font-family: 'EB Garamond', serif;
    transition: 0.3s ease;
}

.header .nav-item a:hover {
    color: var(--main-color);
}

.header .nav-item a.active {
    color: var(--main-color);
    padding-bottom: 0.4rem;
    border-bottom: 2px solid var(--main-color);
}

@media screen and (max-width: 940px) {
    .header {
        max-width: 100%;
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: var(--second-bg-color);
        width: 100%;
        height: 75vh;
        z-index: 999;
        text-align: center;
        transition: 0.3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem;
    }

    .header .logo {
        font-size: 2rem;
    }
}